.input {
  /* width: 340px; */
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 0px;
  height: 35px;
  border-radius: 10px;
  background-color: #ebeced;
  outline: none;
}

.input:focus {
  /* border: 2px solid rgb(189, 189, 189); */
  border: 1px solid;
  border-color: rgb(51 65 85);
  outline: none;
}

.label {
  font-size: 15px;
  font-weight: 600;
  color: orange;
}

/* Select Element CSS */
.container {
  position: relative;
  width: 100%;
  /* min-height: 1.5em; */
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: #ebeced;
  outline: none;
}

.container:focus {
  border: 2px solid;
  border-color: rgb(51 65 85);
}

.value {
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.clear-btn {
  background: none;
  color: #777;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 3px 2px;
  font-size: 1.25em;
}

.clear-btn:focus,
.clear-btn:hover {
  color: #333;
}

.divider {
  background-color: #777;
  align-self: stretch;
  width: 0.05em;
}

.caret {
  translate: 0 25%;
  border: 0.25em solid transparent;
  border-top-color: #777;
}

.options {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  max-height: 135px;
  overflow-y: auto;
  border: 0.05em solid #777;
  border-radius: 0.25em;
  width: 100%;
  left: 0;
  top: calc(100% + 0.25em);
  background-color: white;
  z-index: 100;
}

.options.show {
  display: block;
}

.option {
  color: #000;
  padding: 0.25em 0.5em;
  cursor: pointer;
}

.option.selected {
  background-color: #cacaca;
}

.option.highlighted {
  background-color: #dedede;
  color: #000;
}

.option-badge {
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 3px 3px;
  gap: 0.25em;
  cursor: pointer;
  background-color: white;
  outline: none;
}

.option-badge:hover,
.option-badge:focus {
  background-color: hsl(0, 100%, 90%);
  border-color: hsl(0, 100%, 50%);
}

.option-badge:hover > .remove-btn,
.option-badge:focus > .remove-btn {
  color: hsl(0, 100%, 50%);
}

.option-badge > .remove-btn {
  font-size: 15px;
  color: #777;
}
